import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";

const AuthContext = createContext();

function AuthContextProvider(props) {
    const navigate = useNavigate()


    // const [loggedIn, setLoggedIn] = useState(undefined);
    const loggedIn = true

  async function getLoggedIn() {
    const loggedInRes = await axiosInstance.get('/admin/auth/loggedin')
    
    // setLoggedIn(loggedInRes.data);
    if(loggedIn){
        navigate('/')
    }

  }

  useEffect(() => {
    getLoggedIn();
  }, []);

 
  
  return (
    <AuthContext.Provider value={{ loggedIn, getLoggedIn }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };